<template>
  <ul class="list">
    <li v-for="item in list" :key="item.id">
      <h2 class="caseTitle">{{ item.title }}</h2>
      <p class="subjectAndTree">
        {{ item.bookName ? item.bookName : "自定义教材" }}/{{
          item.tree_fullname ? item.tree_fullname : "自定义目录"
        }}
      </p>

      <div class="info">
        <div class="subAndClass">
          <p>
            <span class="infoIndex">学科：</span
            ><span class="subject">{{ item.subjectName }}</span>
          </p>
          <p>
            <span class="infoIndex">班级：</span
            ><span>{{ item.className }}...</span>
          </p>
        </div>
        <div class="reAndDo">
          <p>
            <span class="infoIndex">批阅数：</span
            ><span>{{ item.reviewTotal }} / {{ item.finishedTotal }}</span>
          </p>
          <p>
            <span class="infoIndex">提交数：</span
            ><span>{{ item.finishedTotal }}/{{ item.studentTotal }}</span>
          </p>
        </div>
      </div>

      <div>
        <span style="color: #666">学案时间：</span>
        <span>{{ item.startTime }} 至 {{ item.blockingTime }}</span>
      </div>

      <div class="fnBtn">
        <van-button
          v-if="item.finishedTotal"
          type="primary"
          size="mini"
          round
          color="rgb(228,126,12)"
          @click.stop="fastReview(item.id)"
          >快速批阅</van-button
        >
        <van-button
          type="primary"
          size="mini"
          round
          color="rgb(235,31,62)"
          @click.stop="dataSis(item.id, item.classId, item.gradeId)"
          >学案数据分析</van-button
        >
      </div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
defineProps(["list"]);

const fastReview = (id) => {
  router.push({ path: "/teacher/fastReview", query: { case_id: id } });
};

const dataSis = (id, classId, gradeId) => {
  router.push({
    path: "/teacher/caseDataSis",
    query: { case_id: id, grade: gradeId },
  });
};

const gotoDetail = (id) => {
  router.push({ path: "/teacher/caseDetail", query: { case_id: id } });
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.listIcon {
  display: flex;
  margin-bottom: 10px;
  h2 {
    margin: 0;
    font-size: 10px;
    margin-left: 10px;
  }
}
.list {
  padding: 10px;
  li {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
    border-radius: 8px;
    .bs();
    h2 {
      margin: 0;
      font-size: 12px;
    }

    .subjectAndTree {
      color: #666;
    }

    .infoIndex {
      color: #777;
    }
    .infoIndex {
      background-color: #fff;
    }
    .subject {
      padding: 0 7px;
      border-radius: 10px;
      background-color: rgb(191, 109, 67);
      color: #fff;
    }

    .fnBtn {
      display: flex;
      justify-content: end;
      :deep(.van-button) {
        margin-left: 10px;
        padding: 0 15px;
      }
    }
  }
}

:deep(.van-divider) {
  margin: 0;
  margin-bottom: 10px;
}

.info {
  display: flex;

  .subAndClass {
    width: 50%;
    p {
      display: flex;
      justify-content: space-between;
      padding-right: 70px;
    }
    p:nth-child(2) {
      padding-right: 80px;
    }
  }

  .reAndDo {
    flex: 1;
    p {
      display: flex;
      justify-content: space-between;
    }
  }
}

.nothing {
  background-color: #fff;
  border-radius: 8px;
}
</style>
