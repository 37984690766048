import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

// 获取学生对应学年列表
export function academicYears() {
  return request.get("/h5/teacher/academicYears");
}

/*
	params = {
		*title, *gradeId, *classIds, subjectId, bookId, treeId, startTime, *blockingTime, *caseType, *questionsJson(json格式数组)
	}
	注：
		questionsJson = [{
			*way_id( 学案为AI类型时 默认1)
			*type_id
			*question( 方式为附件时 附件链接！方式为拍照时 图片链接！方式为文字时 问题文字！ )
			optionsJson( 方式为附件时 空！方式为拍照时 空！方式为文字 且 题目类型为选项或判断 时 选项或对错 对应的json格式数组！ )
			answer( 方式为附件时 空！方式为拍照时 图片链接！方式为文字时 答案文字！ )
		}]
*/
export function apiCreateCase(params) {
  return requestForm.post("/h5/teacher/createCase", params);
}

// 学案列表
/*
  page 默认0
  limit 数量限制
  searchTitle 搜索
  isToday 是否查看今天 默认0 可传0、1
  appointedDate 指定日期 如 2022-08-26
  subjectId
  bookId
  chapterTreeId
  
*/
export function apiGetCasesList(params) {
  return request.get("/h5/teacher/caseList", { params });
}

/*
  params = {
    *case_id
  }
*/

// 学案详情
export function apiGetCaseInfo(params) {
  return request.get("/h5/teacher/caseInfo", { params });
}

/*
  params = {
    *caseId, *studentsNo
  }
*/

// 学生作业详情
export function apiGetCaseQuestions(params) {
  return request.get("/h5/teacher/caseQuestions", { params });
}

/*
  params = {
    *image_url
  }
*/
export function apiGetAiCut(params) {
  return requestForm.post("/h5/teacher/aiCut", params);
}

/*
  params = {
    *gradeId, *classId,caseID
  }
*/

// 学生列表
export function apiGetStudentList(params) {
  return request.get("/h5/teacher/studentList", { params });
}

// 学案删除
/*
  params = {
    *case_ids（内容 1,2,3,4,5）
  }
*/
export function apiCaseDel(params) {
  return requestForm.post("/h5/teacher/caseDel", params);
}

/*
  保存基础信息
  title, *gradeId, *classIds, subjectId, bookId, treeId, startTime, *blockingTime, *caseType
*/
export function apiSaveCaseBase(params) {
  return requestForm.post("/h5/teacherCaseSave/saveCaseBase", params);
}

/**
 * 保存题目信息
 *  *case_id, *question, *answer, *way_id, *type_id, optionsJson
 */

export function apiSaveQuestionAnswer(params) {
  return requestForm.post("/h5/teacher.casesDraft/saveQuestion", params);
}

/**
 * 问题列表
 * params:{
 *   case_id*
 * }
 */
export function apiCaseQuestions(params) {
  return request.get("/h5/teacherCaseSave/caseQuestions", { params });
}

/**
 * 待发布学案列表
 * params：{
 *  page 默认0
    limit 数量限制
    searchTitle 搜索
    isToday 是否查看今天 默认0 可传0、1
    appointedDate 指定日期 如 2022-08-26
    subjectId
    bookId
    chapterTreeId
 * }
 */
export function apiCaseSave(params) {
  return request.get("/h5/teacherCaseSave/caseList", { params });
}

/**
 * 删除待发布学案
 * params：{
 *  *case_ids
 * }
 */
export function apiCaseSaveDel(params) {
  return requestForm.post("/h5/teacherCaseSave/caseDel", params);
}

/**
 * 发布学案
 * params:{
 *  *case_id
 * }
 */
export function apiCreateSaveCase(params) {
  return requestForm.post("/h5/teacherCaseSave/postAndCreateCase", params);
}

/**
 * 草稿学案详情
 * params:{
 *  *case_id
 * }
 */
export function apiDraftCaseList(params) {
  return request.get("/h5/teacherCaseSave/caseInfo", { params });
}

/**v1.2.0
 * 学生人数统计
 * params:{
 *   *gradeId,
 *   *classId,
 *   *caseID
 * }
 */
export function apiGetStudentCount(params) {
  return request.get("h5/teacher/studentStatistics", { params });
}

/**
 * 删除当前题
 * params:{
 *  *case_id,
 *  *question_id,
 * }
 */
export function apiCaseQuestionDel(params) {
  return requestForm.post("h5/teacher.caseDraft/delQuestion", params);
}

/**
 * AI 保存基本信息并编辑题目
 */
export function apiSaveAiData(params) {
  return requestForm.post("h5/teacherCaseSave/saveAiData", params);
}

/**查询学生自我评价详情
 * params={
 *  *case_id(学案ID), *studentsNo(学生账号)
 * }
 */
export function apiCaseStuEvaInfo(params) {
  return request.get("h5/teacherEvaluate/caseStuEvaInfo", { params });
}

/**查询主观题备注详情
 *  params = {
 *    *case_id(学案ID), *question_id(问题ID), *studentsNo(学生账号)
 * }
 */
export function apiGetStudentRemarks(params) {
  return request.get("h5/teacher/studentRemarksInfo", { params });
}

/**老师端学案客观题正确率柱状图
 * params={
 *  *case_id(学案ID)
 * }
 */
export function apiHistogram(params) {
  // params.notShowToast = true;
  return request.get("h5/TeacherStatistics/SubjectiveQuestionObjective", {
    params,
  });
}

/**老师端学案主观题订正数饼图
 * params={
 *  *case_id(学案ID)
 * }
 */
export function apiGetPieData(params) {
  // params.notShowToast = true;
  return request.get("h5/TeacherStatistics/SubjectiveQuestionRevision", {
    params,
  });
}

/** 老师端学生疑问数和理解数折线图
 * params={
 *  *case_id(学案ID)
 */
export function apiLineData(params) {
  // params.notShowToast = true;
  return request.get("h5/TeacherStatistics/StudentDoubt", { params });
}

/** 获取学案Id
 */
export function apiGetNewId() {
  return request.get("/h5/teacher.casesDraft/getNewId");
}

/**保存资料
 * params = {
 *  case_id: 1, //必传
 *  resource_id: 1, //非必传，不传时为新增，传时为修改
		name: '' //必传，文件名称
		url: '' //必传，文件地址
		estimate_duration: 100 //必传，预计最少时长(单位秒)
		ext: '' //必传，文件后缀
 * }
 */
export function apiSaveResource(params) {
  return requestForm.post("/h5/teacher.casesDraft/saveResource", params);
}

/**删除资源
 * params = {
 *  case_id: 1, //必传
		resource_id: 1, //必传
 * }
 */
export function apiDelRes(params) {
  return requestForm.post("/h5/teacher.casesDraft/delResource", params);
}

/**（学生列表）搜索学生
 *  params = {
 *    gradeId: 1,
			classId: 1,
			searchName: ''
 *  }
 */
export function apiStudentList(params) {
  // params.notShowToast = true;
  return request.get("/h5/common.condition/studentList", { params });
}

/** v1.2.0发布学案
 *  params = {
 *    case_id: 1, //必传
			setting: { //必传，设置
				way_id: 1, //必传，学案方式（AI 1，题目 2）
				start_time: '2022-10-20 10:00', //非必传，开始时间，不传时默认当前时间
				blocking_time: '2022-10-20 10:30', //必传，截止时间
				post_object_type_id: 1, 发布对象 （班级1，学生2，年级3）
				grade_ids: '1,2', //根据发布对象判断是否必传
				class_ids: '1,2, //根据发布对象判断是否必传
				student_ids: '1,2' //根据发布对象判断是否必传
			}, 
			info: { //必传，基本信息
				title: '', //必传，学案标题
				subject_id: 1, //必传，科目ID
				relation_book_way_id: 1, //必传，教材方式（选目录1，自定义2）
				book_id: 1, //非必传
				tree_id: 1, //非必传
				book_str: '', //非必传
				tree_str: '', //非必传
			}, 
 *  }
 */
export function apiPostCase(params) {
  return requestForm.post("/h5/teacher.casesDraft/postCase", params);
}

// 保存学案信息
export function apiSaveCase(params) {
  return requestForm.post("/h5/teacher.casesDraft/saveCase", params);
}

/** v1.2.0获取学案详情
 *  params = {
 *    case_id: 1,
 *  }
 */
export function apiCasesDraftInfo(params) {
  return request.get("/h5/teacher.casesDraft/info", { params });
}

/** v1.2.0获取草稿学案列表
 * params = {
 *  	page: 1,
			limit: 10,
			searchTitle: '',
			subjectId: '',
			bookId: '',
			treeId: '',
			gradeId: '',
			classId: '', 
 * }
 */
export function apiGetDraftList(params) {
  return request.get("/h5/teacher.casesDraft/lists", { params });
}

/**v1.2.0 删除草稿学案
 * params = {
 *  case_id: '1,2', //可批量删除
 * }
 */
export function apiDelDraftList(params) {
  return requestForm.post("/h5/teacher.casesDraft/del", params);
}

/**v1.2.0获取问题列表
 * params = {
 *  case_id: 1
 * }
 */
export function apiGetQuestionList(params) {
  return request.get("/h5/teacher.casesDraft/questionList", { params });
}

/**v1.2.0获取资源列表
 *  params = {
 *    case_id: 1,
 *  }
 */
export function apiGetResourceList(params) {
  return request.get("/h5/teacher.casesDraft/resourceList", { params });
}

/**v1.2.0学案详情，获取资源列表
 * params = {
 *  case_id: 1, //必传
		gradeId: 1, //必传
		classId: 1, //必传
 * }
 */
export function apiGetCaseResource(params) {
  params.notShowToast = true;
  return request.get("/h5/teacher/caseResource", { params });
}

/**学案详情 - 资源学生列表
 * params = {
 *    caseID: 1, //必传
			gradeId: 1, //必传
			classId: 1, //必传
			resourceID: 1, //必传 资源ID
			status: 0, //必传 0全部 1未学习 2已学习
 * }
 */
export function apiGetResStudentList(params) {
  params.notShowToast = true;
  return request.get("/h5/teacher/studentListForResource", { params });
}

/**学案详情 - 资源学生列表tab统计
 * params = {
 *    caseID: 1, //必传
			gradeId: 1, //必传
			classId: 1, //必传
			resourceID: 1, //必传 资源ID 
 * }
 */
export function apiGetResStudentTotal(params) {
  params.notShowToast = true;
  return request.get("/h5/teacher/studentStatisticsForResource", { params });
}

/**获取评阅左侧信息
 * 参数：
			{
				caseId: 1,
				gradeId: 1,
				classId: 1,
			}
 */
export function apiGetCasesResource(params) {
  return request.get("/h5/teacher.casesReview/info", { params });
}

/**学案详情 - 资源图表
 * 参数：
		{
			caseID: 1, //必传
			gradeId: 1, //必传
			classId: 1, //必传
			resourceID: 1, //必传 资源ID
		}
 */
export function apiStudentResourceLeanTime(params) {
  params.notShowToast = true;
  return request.get("/h5/teacherStatistics/studentResource", { params });
}

/**学案详情 - 资源图表
 参数： case_id，gradeId，classId
 */
export function apiStudentLearningDuration(params) {
  params.notShowToast = true;
  return request.get("h5/teacherStatistics/studentLearningDuration", {
    params,
  });
}

/**获取评阅学生列表
 * 参数：
		{
			caseId: 1,
			gradeId: 1,
			classId: 1,
			status: 0, //状态 0全部1评阅2未评阅
		}
 */
export function apiPyStudentList(params) {
  params.notShowToast = true;
  return request.get("/h5/teacher.casesReview/studentList", { params });
}

/**获取评阅学生列表
 * 参数：
		{
			caseId: 1,
			studentsNo: 'LTP20221010',
		}
 */
export function apiGetStudentInfo(params) {
  return request.get("/h5/teacher.casesReview/studentInfo", { params });
}

/**获取评阅学生答案
 * 参数：
		{
			caseId: 1,
			studentsNo: 'LTP20221010',
		}
 */
export function apiGetStudentAnswer(params) {
  return request.get("/h5/teacher.casesReview/studentAnswer", { params });
}

/**获取下一个学生
 * 参数：
		{
			caseId: 1,
			studentsNo: 'LTP20221010',
			reviewStr: '', //评阅内容
		}
 */
export function apiGetNextStudent(params) {
  return requestForm.post("/h5/teacher.casesReview/toReview", params);
}

/**删除学案v1.2.0
 * 参数： caseId，gcJson
 */
export function apiCaseDelete(params) {
  return requestForm.post("h5/teacher/caseDelete", params);
}

/**首页统计 */
export function apiStatistIndex() {
  return request.get("h5/teacher.index/statistics");
}

/**老师批改主观题作业
 * params = {
 *  answer_id
 *  file_url
 *  text
 * }
 */
export function apiCorrection(params) {
  return requestForm.post("h5/Correction/correction", params);
}
