import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

export const apiGetGrade = () => {
  return request.get("h5/teacher.grades/lists");
};

export const apiGetClassList = (params) => {
  return request.get("h5/teacher.classes/lists", { params });
};

export const apiGetSubject = (params) => {
  return request.get("h5/teacher.subjects/lists", { params });
};

export const apiGetNewId = () => {
  return request.get("/h5/teacher.casesDraft/getNewId");
};

export const apiGetCaseInfo = (params) => {
  return request.get("/h5/teacher/caseInfo", { params });
};

export const apiGetStudentList = (params) => {
  return request.get("/h5/teacher/studentList", { params });
};

export const apiGetStudentInfo = (params) => {
  return request.get("/h5/teacher.casesReview/studentInfo", { params });
};

export const apiGetSTudentAnswer = (params) => {
  return request.get("/h5/teacher.casesReview/studentAnswer", { params });
};

export const apiGetNextStudent = (params) => {
  return requestForm.post("/h5/teacher.casesReview/toReview", params);
};

/**查看批阅
 * params ={
 *  student_no
 *  answer_id
 * }
 */
export function apiGetEdiImg(params) {
  return request.get("/h5/teacher.CasesReview/correctionData", { params });
}

/**下一个未批阅的学案 */
export const apiNextUnReviewCase = (params) => {
  return request.get("/h5/teacher.Cases/nextUnReviewCase");
};

/**待批阅学生数量
 * case_id
 */
export const apiGetPendingReview = (params) => {
  return request.get("/h5/teacher.CasesReview/pendingReviewStudents", {
    params,
  });
};
